(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function () {
  var method;

  var noop = function noop() {};

  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

var takeoutURI = "https://takeout.pizzahut.lu/";
var $newUrI = '';
var hasPopup = false;
var belongsTo = "";
var isMovingPage = null;
var d = new Date();
var n = d.getFullYear();
$(document).ready(function (e) {
  $(document).on('click', '.btn-order', function (e) {
    if ($('.select.long').hasClass('error')) {
      $('.select.long').removeClass('error');
    }

    var $selectedValue = $('#restauChoice').val();

    switch ($selectedValue) {
      case '0':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_beggen/products/pizza";
        hasPopup = false;
        belongsTo = "HS";
        break;

      case '1':
        $newUrI = takeoutURI + main_lang + "/pizzahut_luxembourg_ville/products/pizza";
        hasPopup = false;
        belongsTo = "HS";
        break;

      case '2':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_mersch/products/pizza";
        hasPopup = "panpizza_" + main_lang + ".jpg";
        hasPopup = false;
        belongsTo = "LV";
        break;

      case '3':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_pommerloch/products/pizza";
        hasPopup = "panpizza_" + main_lang + ".jpg";
        hasPopup = false;
        belongsTo = "LV";
        break;

      case '4':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_strassen/products/pizza";
        hasPopup = false;
        belongsTo = "HS";
        break;

      case '5':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_wasserbillig/products/pizza";
        hasPopup = "panpizza_" + main_lang + ".jpg";
        hasPopup = false;
        belongsTo = "LV";
        break;

      case '6':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_foetz/products/pizza";
        hasPopup = false;
        belongsTo = "HS";
        break;

      case '7':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_remich/products/pizza";
        hasPopup = "panpizza_" + main_lang + ".jpg";
        hasPopup = false;
        belongsTo = "LV";
        break;

      case '8':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_wemperhardt/products/pizza";
        hasPopup = "panpizza_" + main_lang + ".jpg";
        hasPopup = false;
        belongsTo = "LV";
        break;

      case '9':
        $newUrI = takeoutURI + main_lang + "/pizza_hut_rodange/products/pizza";
        hasPopup = false;
        belongsTo = "HS";
        break;

      default:
        $('.select.long').addClass('error');
    }

    if ($selectedValue >= 0 && $selectedValue != null) {
      if (belongsTo == "LV") {
        if (hasPopup !== false) {
          $(".popupImage").attr("src", "/dist/images/" + hasPopup);
          $(".popupWrapper").fadeIn(150, function () {
            isMovingPage = setTimeout(function () {
              window.open($newUrI, '_blank');
            }, 7000);
          });
        } else {
          window.open($newUrI, '_blank');
        }
      } else {
        window.open($newUrI, '_blank');
      }
    }
  });
  $(document).on('click', '.popupWrapper', function () {
    if (hasPopup !== false && $newUrI != "") {
      clearTimeout(isMovingPage);
      $('.popupWrapper').fadeOut(150, function () {
        window.open($newUrI, '_blank');
      });
    } else {
      $('.popupWrapper').fadeOut(150);
    }
  });
  $(document).on('click', '.header-lang-link', function (e) {
    var $this = $(this).parent();

    if ($this.hasClass('open')) {
      $this.removeClass('open');
    } else {
      $this.addClass('open');
    }
  });
  $('.yToday').text(n);
});

},{}]},{},[1]);
